import { useLocation } from '@reach/router';
import axios from 'axios';
import {
  AgeStep,
  ageValidation,
  BadFitStep,
  EmailAndFirstNameStep,
  emailValidation,
  firstNameValidation,
  LoadingStep,
  PhoneStep,
  phoneValidation,
  SavingsStep,
  savingsValidation,
  StartingDepositStep,
  startingDepositValidation,
  WhenRetiringOptions,
  WhenRetiringStep,
  whenRetiringValidation,
} from 'component-library/components/form/fields';
import {
  FormButton,
  FormikWizard,
  handleTrack,
} from 'component-library/components/form/FormikWizard';
import { WizardStep } from 'component-library/components/form/WizardStep';
import { Flex } from 'component-library/components/layout/Flex';
import { graphql } from 'gatsby';
import { CardSignUpContainerFragment as CardSignUpContainerTypes } from 'graphqlTypes';
import { getUrlTrackingDetails } from 'helpers/getUtmDataFromUrl';
import { validateFields } from 'page-components/welcome/signUpUtils';
import React, { FC, useState } from 'react';
import { identify } from 'utils/analytics';
import * as yup from 'yup';

import { CardConfirmationPage } from './CardConfirmationPage';

export const CardSignUpContainerFragment = graphql`
  fragment CardSignUpContainer on Query {
    ...CardConfirmationPage
  }
`;

const CardSignUpFormSchema = {
  currentAge: yup.object().shape({
    currentAge: ageValidation,
  }),
  whenRetiring: yup.object().shape({
    whenRetiring: whenRetiringValidation,
  }),
  startingDeposit: yup.object().shape({
    startingDeposit: startingDepositValidation,
  }),
  savings: yup.object().shape({
    savings: savingsValidation,
  }),
  nameAndEmail: yup.object().shape({
    email: emailValidation,
    firstName: firstNameValidation,
  }),
  phone: yup.object().shape({
    phone: phoneValidation,
  }),
};

enum CardSignUpSteps {
  CurrentAge = 'currentAge',
  WhenRetiring = 'whenRetiring',
  StartingDeposit = 'startingDeposit',
  Savings = 'savings',
  NameAndEmail = 'nameAndEmail',
  Phone = 'phone',
  Loading = 'loading',
  Confirmation = 'confirmation',
  BadFit = 'badFit',
}

interface CardSignUpFormData {
  currentAge: number;
  whenRetiring: WhenRetiringOptions;
  startingDeposit: number;
  savings: number;
  email: string;
  firstName: string;
  phone: number;
}

const FORM_NAME = 'Card Sign Up';

interface CardSignUpContainerProps {
  data: CardSignUpContainerTypes;
}

export const CardSignUpContainer: FC<CardSignUpContainerProps> = ({ data }) => {
  const [formIsSubmitting, setFormIsSubmitting] = useState(false);
  const [applicationFormUrl, setApplicationUrl] = useState('');
  const location = useLocation();
  const utmTrackingDetails = getUrlTrackingDetails(location.search);

  const initialValues = {
    currentAge: null,
    whenRetiring: null,
    startingDeposit: null,
    savings: null,
    firstName: null,
    email: null,
    phoneNumber: null,
  };

  const onSubmit = async (data: CardSignUpFormData) => {
    setFormIsSubmitting(true);
    handleTrack(CardSignUpSteps.NameAndEmail, FORM_NAME);

    const resp = await axios.request({
      method: 'post',
      url: '/api/createBankingMarketingLead',
      data: { ...data, ...utmTrackingDetails },
    });

    const { user_id, application_form_url } = resp.data;
    setFormIsSubmitting(false);
    if (user_id && application_form_url) {
      identify(user_id);
      setApplicationUrl(application_form_url);
    } else {
      window.location.replace('https://app.retirable.com/login');
    }
  };

  return (
    <FormikWizard
      formName={FORM_NAME}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={CardSignUpFormSchema}
    >
      {(helpers, currentPageIndex, setPageIndex) => (
        <Flex column>
          <WizardStep
            pageName={CardSignUpSteps.CurrentAge}
            buttonText="Next Question"
            hideBackButton
          >
            <AgeStep
              heading="To start, what's your age?"
              body="Our high-yield card is currently only for adults 50+."
              modalBody="We've designed our services around the needs of adults close to or in retirement, so we're just checking that we'll be able to meet your needs."
            />
          </WizardStep>
          <WizardStep
            pageName={CardSignUpSteps.WhenRetiring}
            buttonText="Next Question"
          >
            <WhenRetiringStep modalBody="Your high-yield cash account comes with different benefits, first of which is a free retirement consultation and plan from one of our financial advisors. Knowing your approximate retirement timeline will help us pair you with the right advisor." />
          </WizardStep>
          <WizardStep
            pageName={CardSignUpSteps.StartingDeposit}
            buttonText="Next Question"
          >
            <StartingDepositStep />
          </WizardStep>
          <WizardStep
            pageName={CardSignUpSteps.Savings}
            buttonText="Next Question"
          >
            <SavingsStep body="An estimate is ok!" />
          </WizardStep>
          <WizardStep
            pageName={CardSignUpSteps.NameAndEmail}
            buttonText="Next Question"
          >
            <EmailAndFirstNameStep
              heading="What's your name and email?"
              body="You'll use this email to log in to your cash account."
            />
          </WizardStep>
          <WizardStep pageName={CardSignUpSteps.Phone}>
            <PhoneStep
              heading="What's your phone number?"
              body="Just like with any bank account, we need your contact info."
            />
            <FormButton
              withCaret
              marginTop={40}
              type="button"
              disabled={helpers.errors.phone || formIsSubmitting}
              onClick={async () => {
                const formIsValid = await validateFields<CardSignUpFormData>(
                  helpers,
                  ['phone'],
                );

                if (formIsValid) {
                  await onSubmit(helpers.values);
                  handleTrack(CardSignUpSteps.Phone, FORM_NAME);
                  setPageIndex(currentPageIndex + 1);
                }
              }}
            >
              Check Account Eligbility
            </FormButton>
          </WizardStep>
          <WizardStep pageName={CardSignUpSteps.Loading} hideBackButton>
            <LoadingStep
              onComplete={() => {
                handleTrack(CardSignUpSteps.Loading, FORM_NAME);
                setPageIndex(currentPageIndex + 1);
              }}
            />
          </WizardStep>
          <WizardStep pageName={CardSignUpSteps.BadFit} hideBackButton>
            <BadFitStep
              onComplete={() => setPageIndex(currentPageIndex + 1)}
              age={helpers.values.currentAge}
              whenRetiring={helpers.values.whenRetiring}
            />
          </WizardStep>
          <WizardStep pageName={CardSignUpSteps.Confirmation} hideBackButton>
            <CardConfirmationPage
              applicationFormUrl={applicationFormUrl}
              data={data}
            />
          </WizardStep>
        </Flex>
      )}
    </FormikWizard>
  );
};
