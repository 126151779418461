import { getCurrentInterestRateDetails } from 'common/interest-rate-components/base';
import { Grid } from 'common/layouts/grid/Grid';
import { GridColumn } from 'common/layouts/grid/GridColumn';
import {
  ButtonColor,
  PrimaryButton,
} from 'component-library/components/buttons/PrimaryButton';
import { Flex } from 'component-library/components/layout/Flex';
import { Body } from 'component-library/components/typography/Body';
import { Subheading } from 'component-library/components/typography/Subheading';
import { PersonalPlanWithSpendCardSmall } from 'component-library/images/PersonalPlanWithSpendCard';
import { device } from 'component-library/styles/device';
import { graphql } from 'gatsby';
import { CardConfirmationPageFragment as CardConfirmationPageTypes } from 'graphqlTypes';
import React, { FC } from 'react';
import styled from 'styled-components';

const ImageWrapper = styled(Flex)`
  margin-right: -17px;
  margin-left: auto;
  max-width: 400px;

  ${`@media ${device.lg}`} {
    margin-right: -33px;
  }
`;

export const CardConfirmationPageFragment = graphql`
  fragment CardConfirmationPage on Query {
    ...GetCurrentInterestRateDetails
  }
`;

interface CardConfirmationPageProps {
  data: CardConfirmationPageTypes;
  applicationFormUrl: string;
}

export const CardConfirmationPage: FC<CardConfirmationPageProps> = ({
  data,
  applicationFormUrl,
}) => {
  const { formattedApy } = getCurrentInterestRateDetails(data);

  return (
    <>
      <Subheading marginBottom={48} variant={1}>
        Great - let&apos;s open your high-yield account!
      </Subheading>
      <Grid cols={2} gap={5} spacing={3}>
        <GridColumn span={2} desktopSpan={1}>
          <Subheading variant={2} marginBottom={8}>
            What&apos;s Next?
          </Subheading>

          <Body variant={3}>1. Finish opening your account</Body>
          <Body variant={3}>2. Receive and activate your card</Body>
          <Body variant={3}>3. Enjoy {formattedApy} APY on your savings</Body>
          <Body marginBottom={24} variant={3}>
            4. Request a free retirement planning session
          </Body>
          <PrimaryButton
            withCaret
            buttonColor={ButtonColor.Peach}
            onClick={() => window.open(applicationFormUrl)}
            type="button"
          >
            Finish Opening My Account
          </PrimaryButton>
        </GridColumn>
        <GridColumn span={2} desktopSpan={1}>
          <ImageWrapper>
            <PersonalPlanWithSpendCardSmall />
          </ImageWrapper>
        </GridColumn>
      </Grid>
    </>
  );
};
