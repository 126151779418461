import Layout from 'common/layouts/Layout';
import { Navigation } from 'component-library/components/navigation/Navigation';
import { PageWrapper } from 'component-library/components/wrappers/PageWrapper';
import { colors } from 'component-library/styles/colors';
import { SEO } from 'components/SEO';
import { graphql } from 'gatsby';
import { WelcomeCardSignUpQuery } from 'graphqlTypes';
import { Footer, FooterTemplate } from 'page-components/shared/footer';
import { WelcomeWrapper } from 'page-components/welcome/welcomeUtils';
import { CardSignUpContainer } from 'page-components/welcome-card/sign-up/CardSignUpContainer';
import React, { FC } from 'react';

interface WelcomeCardSignUpProps {
  data: WelcomeCardSignUpQuery;
}

const WelcomeCardSignUp: FC<WelcomeCardSignUpProps> = ({ data }) => (
  <Layout>
    <Navigation simpleNav />
    <PageWrapper background={colors.greenLight}>
      <WelcomeWrapper maxWidth={900}>
        <CardSignUpContainer data={data} />
      </WelcomeWrapper>
      <Footer template={FooterTemplate.LegalDisclaimerOnly} />
    </PageWrapper>
  </Layout>
);

export default WelcomeCardSignUp;

export const Head = () => (
  <SEO
    title="Look Forward To More In Retirement"
    description="Retirable® gives adults 50+ retirement income management and guidance to help them confidently maintain a comfortable lifestyle."
  />
);

export const query = graphql`
  query WelcomeCardSignUp {
    ...CardSignUpContainer
  }
`;
